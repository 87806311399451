<script setup lang="ts">
import { computed, watch, ref, onMounted } from "vue";
import { vElementVisibility } from "@vueuse/components";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VInstallAppBanner from "@magnit/core/src/containers/VInsallAppBanner/VInstallAppBanner.vue";
import { usePwaStore } from "~/store/pwa";
import { usePlatformStore } from "~/store/platform";
import AppLogo from "~/assets/svg/В1_logo.svg";
import { usePwaIosInstructionModal } from "~/composables/useModals";

const pwaStore = usePwaStore();
const platformStore = usePlatformStore();
const iosInstructionModal = usePwaIosInstructionModal();
const { send } = AnalyticManager;

const isElementVisible = ref(false);
const visibilityEventEmitted = ref(false);
const isClosed = ref(false);

const showOpenButton = computed(() => pwaStore.state.appInstalled);
const isShowBanner = computed(
  () =>
    !isClosed.value &&
    (pwaStore.state.installPromptBannerOpen ||
      pwaStore.state.showIosTutorial ||
      showOpenButton.value),
);

const onClose = () => {
  pwaStore.state.installPromptBannerOpen = false;
  pwaStore.state.showIosTutorial = false;
  isClosed.value = true;
};

const onOpenPwaClick = () => {
  send("App:Open:Banner:Click");
  if (platformStore.platformInfo.pwaLinkSupported) {
    window.open(pwaStore.DEFAULT_PWA_LINK, "_blank");
  }
};

const onButton = () => {
  send("App:Prompt:Banner:Click");
  if (pwaStore.state.showIosTutorial) {
    iosInstructionModal.open();
    return;
  }
  pwaStore.showPrompt();
};

const onElementVisibility = (visibility: boolean) => {
  isElementVisible.value = visibility;
};

const onEventsTriggering = () => {
  if (
    pwaStore.state.installPromptBannerOpen ||
    pwaStore.state.showIosTutorial
  ) {
    visibilityEventEmitted.value = true;
    send("App:Prompt:Banner:View");
  } else if (showOpenButton.value) {
    visibilityEventEmitted.value = true;
    send("App:Open:Banner:View");
  }
};

watch(isElementVisible, (next) => {
  if (next && !visibilityEventEmitted.value) {
    onEventsTriggering();
  }
});

watch(showOpenButton, (next) => {
  if (visibilityEventEmitted.value && next) {
    send("App:Open:Banner:View");
  }
});

onMounted(() => {
  if (isElementVisible.value) {
    onEventsTriggering();
  }
});
</script>

<template>
  <VInstallAppBanner
    v-element-visibility="onElementVisibility"
    title="Первый выбор"
    subtitle="Карта В1 и купоны"
    :open="isShowBanner"
    :button-title="pwaStore.state.appInstalled ? 'Открыть' : 'Установить'"
    :loading="pwaStore.state.installInProgress"
    bottom-shadow
    @click="pwaStore.state.appInstalled ? onOpenPwaClick() : onButton()"
    @close="onClose"
  >
    <template #icon>
      <AppLogo />
    </template>
  </VInstallAppBanner>
</template>
